import React, { useState } from "react";
import styled from "styled-components";

import AddForm from "./components/AddForm";

const RightSide = styled.div`
  position: relative;
  width: 100%;
  // background: #eff0f7;
  height: 100vh;
`;

function AddMember() {
  return (
    <div className="grid-sec">
      <RightSide open={true}>
        <div className="margin-sec">
          <AddForm />
        </div>
      </RightSide>
    </div>
  );
}

export default AddMember;
