import React from "react";
import ReactApexChart from "react-apexcharts";
import Box from "../Box";

const AreaChart = ({ name, data, categories }) => {
  const series = [
    {
      name: name,
      data: data,
    },
  ];

  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    grid: {
      show: false, // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false, //or just here to disable only x axis grids
        },
      },
      yaxis: {
        lines: {
          show: false, //or just here to disable only y axis
        },
      },
    },
    colors: ["#80CBC4"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: categories,
    },
  };

  return (
    <Box>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        width="85%"
        height={300}
      />
    </Box>
  );
};

export default AreaChart;
