import React, { useState } from "react";
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import DeleteComponent from "../../../components/DeleteComponent";
import { deletePlan, updateAgentDetails } from "../../../mosqueAction";
import { Select } from "antd";
import { getRolesOption } from "../../../../../util/Utility";
import { truncate } from "lodash";

const PlanCards = styled.div`
  position: relative;
  overflow: hidden;
  padding: 30px 20px 10px 20px;
  background: #ffffff;
  border: 0.804144px solid #eff0f7;
  box-shadow: 0px 2.37681px 16.6376px rgba(98, 106, 128, 0.16);
  border-radius: 12px;
  margin-bottom: 20px;
  width: 350px;

  .planNo {
    font-weight: 700;
    font-size: 20px;
    color: #1a1a1a;
    text-decoration-line: underline;
  }

  .planNo span {
    font-weight: 700;
    font-size: 15px;
    color: #80cbc4;
    text-transform: uppercase;
    text-decoration-line: underline;
  }

  .Dashborder {
    border-top: 1px dashed #a0aec0;
    margin: 10px 0;
  }
  .DashborderVertical {
    border-right: 1px dashed rgb(160, 174, 192);
    height: 41px;
    width: 1px;
  }

  .price {
    font-weight: 700;
    font-size: 38px;
    color: #1a1a1a;
  }

  .price del {
    font-weight: 600;
    font-size: 18px;
    color: #a0aec0;
    margin-left: 10px;
  }

  .planBtn {
    background: #a0aec0;
    border-radius: 200px;
    font-weight: 700;
    font-size: 14px;
    color: #ffffff;
    height: 44px;
    width: 100%;
    border: none;
    margin: 10px 0;
  }
  .deleteBtn {
    font-weight: 400;
    font-size: 18px;
    color: #fe1717;
    border: none;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    span {
      margin-left: 10px;
    }
  }
  .editBtn {
    font-weight: 700;
    font-size: 18px;
    color: #d0004b;
    border: none;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    span {
      margin-left: 10px;
    }
  }

  .planDetail {
    font-weight: 600;
    font-size: 18px;
    color: #1a1a1a;
    margin: 15px 0;
  }

  ul {
    margin-bottom: 0px;
  }

  li {
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: rgba(26, 26, 26, 0.67);
  }
`;

const TextBox = styled(Box)`
  color: #7E7E7E;
  word-break: break-all;
`;

const options = getRolesOption();

const ExistAdmin = ({}) => {
  const dispatch = useDispatch();
  const agent = useSelector((state) => state.mosqueReducer.agent);
  const agentsList = useSelector((state) => state.mosqueReducer.agentsList);
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  const handleAgentDetails = (id, keyName, keyValue) => {
      const details = {};
      if (keyName === "role") {
          details.role = keyValue
      }
      if (keyName === "is_active") {
          details.is_active = keyValue
      }
      dispatch(updateAgentDetails(id, details))
  }

  return (
    <div>
      <div className="row">
        {agentsList &&
            agentsList.map((item, index) => (
            <div className="col-md-4" key={index}>
              <PlanCards>
                <Box display="flex" alignItems="center" justifyContent="space-between" gridGap="30px" width="100%">
                  <Box width="50%">
                    <h5 style={{ fontWeight: 700 }} title={item?.contact_name}>{truncate(item?.contact_name, { length: 20 })}</h5>
                    <TextBox color="#7E7E7E">{item?.contact_phone}</TextBox>
                    <TextBox>{item?.contact_email}</TextBox>
                  </Box>
                  <Box>
                    <img src={item?.profile_pic} alt="profile_pic" width="60px" height="60px" style={{borderRadius: "50px"}} />
                  </Box>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box mt="24px">
                    <Select
                        size={"middle"}
                        value={item?.role || "Select role"}
                        defaultValue="Select role"
                        onChange={(value) => handleAgentDetails(item?._id,"role", value)}
                        style={{ width: "150px" }}
                        options={options}
                    />
                  </Box>
                  <Box mt="24px">
                      <label className="switch">
                          <input type="checkbox"
                                 disabled={agent?._id === item?._id}
                                 onChange={() => handleAgentDetails(item?._id, "is_active", !item?.is_active)}
                                 checked={item?.is_active}/>
                          <span className={`slider round`}></span>
                      </label>
                  </Box>
                </Box>
              </PlanCards>
            </div>
          ))}
      </div>

      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <DeleteComponent
            text={"You will permanently loose this plan’s details and payment information."}
            handleDelete={() => dispatch(deletePlan(id))}
            show={show} setShow={setShow} />
      </DefaultModal>
    </div>
  );
};

export default ExistAdmin;
