import React from "react";
import ReactApexChart from 'react-apexcharts';
import Box from "../Box";

const CurveLineChart = ({ dataSet, colors, categories }) => {
  const series = dataSet;
  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    colors: colors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: false  //or just here to disable only x axis grids
        }
      },
      yaxis: {
        lines: {
          show: true  //or just here to disable only y axis
        }
      },
    },
    yaxis: {
      min: 5,
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      show: true
    }
  };

  return (
    <Box>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
        width="98%"
      />,.
    </Box>
  );
};

export default CurveLineChart;
