import React from 'react'
import BlogCard from './BlogCard'

function PublishedBlog() {
  return (
    <div>
        <BlogCard />
    </div>
  )
}

export default PublishedBlog