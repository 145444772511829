import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { AUTHENTICATED } from "../../../../constants/authEnums";
import BasicLoader from "../../../../components/Loaders/BasicLoader";

const RedirectPage = ({ isMosque, isCustomer, isAdmin }) => {
  const authState = useSelector((state) => state?.appReducer?.authState);
  const id = useSelector((state) => state?.appReducer?.activeUserId);

  useEffect(() => {
    if (authState === AUTHENTICATED) {
      if (isMosque && id) {
        window.location.href = `/mosque/${id}/dashboard`;
      } else if (isCustomer && id) {
        window.location.href = `/customer/${id}/home`;
      } else if (isAdmin) {
        handleLogOut();
      }
    } else {
      window.location.href = `/login`;
    }
  }, [authState, id])

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("user_type");
    window.location = "/login";
  };

  return <BasicLoader />;
};

export default RedirectPage;
