import React, { useState } from "react";
import styled from "styled-components";

import MosqueForm from "./components/MosqueForm";

const RightSide = styled.div`
  position: relative;
  width: 100%;
`;

function MosqueDetails() {
  return (
    <div className="grid-sec">
      <RightSide open={true}>
        <div className="margin-sec">
          <MosqueForm />
        </div>
      </RightSide>
    </div>
  );
}

export default MosqueDetails;
