import React from 'react'
import FundTab from './components/FundTab'

function Fund() {
  return (
    <div>
        <FundTab />
    </div>
  )
}

export default Fund