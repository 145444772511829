import React from "react";
import Box from "../../../../../components/Box";

const ProgressBar = ({ item }) => {

  const renderGrowth = (cur, prev) => {
    let percentage = 0;
    if (prev !== 0) {
      percentage = (prev / cur) * 100;
    }
    return Math.abs(percentage || 0).toFixed(0);
  }

  return (
    <div className="progress_bar mt-4">
      <div>
        <div className="progress_skill">
          <Box className="skill1 html" width={`${renderGrowth(item?.targetAmount, item?.total_amount_paid)}%`}>
            <img
              src="https://ik.imagekit.io/rzlzf40okya/elixir/dot.svg"
              className="skill_im"
            />
          </Box>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <label>
            <b>$0</b>
          </label>
          <h6>
            <b>${item?.targetAmount}</b>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
