import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import Header from "../Header";
import LeftSidebar from "../LeftSidebar";
import { fetchOrganisationEntities, fetchApprovalList } from "../../mosqueAction";

// const RightHeader = styled.div`
//   position: fixed;
//   background: #FAF4FD;
//   height: 91px;
//   z-index: 999;
//   width: ${(props) => (props.open ? "100%" : "91%")};
// `;
const RightSide = styled.div`
  // padding-top: 150px;
  // padding-left: 55px;
  // padding-right: 55px;
  padding:7.5rem 2rem 2rem 2rem;
  background: #FAF4FD;
  padding-bottom: 100px;
  height: 100vh;
  overflow-y: scroll;
  @media (min-resolution: 130dpi) and (max-resolution: 140dpi){
    padding:6.8rem 2rem 100px;
  }
  @media (min-resolution: 140dpi) and (max-resolution: 150dpi){
    padding:6.8rem 2rem 100px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #9197B3;
    border-radius: 5px;
  }
`;
const RightSideSection = styled.div`
  width: ${(props) => (props.open ? "100%" : "100%")};
`;

const NavbarSidebarGlobal = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const agentDetails = useSelector(state => state.mosqueReducer?.agent);
  const activeEntity = useSelector(state => state.mosqueReducer?.activeEntity);

  useEffect(() => {
    if (agentDetails?.organisation_id) {
      dispatch(fetchOrganisationEntities(agentDetails?.organisation_id));
    }
  }, [agentDetails]);

  const renderContainer = () => {
    if (
        location?.pathname === `/mosque/${activeEntity?._id}/pay-now`
    ) {
      return <div className="container-fluid p-0">{children}</div>;
    }
    return (
        <>
          <div className="container-fluid p-0">
            <div className="d-flex">
              <LeftSidebar open={!!open} setOpen={setOpen} />
              <RightSideSection open={!!open}>
                {/* <RightHeader open={open}> */}
                  <Header />
                {/* </RightHeader> */}
                <RightSide open={true}>
                  {children}
                </RightSide>
              </RightSideSection>
            </div>
          </div>
        </>
    );
  }

  return <>{renderContainer()}</>;
};

NavbarSidebarGlobal.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NavbarSidebarGlobal;
