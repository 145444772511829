import React  from "react";
import ExistAdmin from "./ExistAdmin";

const ExistingAdmins = ({}) => {
  return (
    <div>
      <ExistAdmin />
    </div>
  );
};

export default ExistingAdmins;
