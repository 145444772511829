import React, { useState, useEffect }  from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import MosqueForm from "./components/MosqueForm";
import { callApiV2 } from "../../../../util/apiCaller";
import { showNotification } from "../../../../util/Utility";

const RightSide = styled.div`
  position: relative;
  width: 100%;
`;

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const MosqueAdd = () => {
    const query = useQuery();

    const [details, setDetails] = useState();

    const entityId = query.get("entityId");
    const approvalId = query.get("approvalId");

    useEffect(() => {
        if (entityId) {
            callApiCustomer();
        }
    }, [entityId]);

    const callApiCustomer = async () => {
        const res = await callApiV2({
            method: "get",
            endpoint: `entity/${entityId}/details`,
        });
        if (res?.status === "Success") {
            setDetails(res?.data?.entity);
        } else {
            showNotification('error', "Something went wrong...");
        }
    }

  return (
    <div className="grid-sec">
      <RightSide open={true}>
        <div className="margin-sec">
          <MosqueForm detailsMosque={details} entityId={entityId} approvalId={approvalId} />
        </div>
      </RightSide>
    </div>
  );
}

export default MosqueAdd;
