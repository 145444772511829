import React, { useState } from "react";
import styled from "styled-components";

import MemberTable from "./components/MemberTable";

const RightSide = styled.div`
  position: relative;
  width: 100%;
`;

function MembersListPage() {
  return (
    <div className="grid-sec" style={{marginTop: "-30px"}}>
      <RightSide open={true}>
        <div>
          <MemberTable />
        </div>
      </RightSide>
    </div>
  );
}

export default MembersListPage;
