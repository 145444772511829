import React from "react";
import ReactApexChart from 'react-apexcharts';
import Box from "../Box";

const LineChart = ({ dataSet, colors, categories }) => {
  const series = dataSet;
  const options = {
    chart: {
      toolbar: {
        show: false
      }
    },
    colors: colors,
    dataLabels: {
      enabled: true,
    },
    grid: {
      show: true,      // you can either change hear to disable all grids
      xaxis: {
        lines: {
          show: true  //or just here to disable only x axis grids
        }
      },
      yaxis: {
        lines: {
          show: true  //or just here to disable only y axis
        }
      },
    },
    xaxis: {
      categories: categories,
    },
    legend: {
      show: false
    }
  };

  console.log("series", series);
  return (
    <Box>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
         width="98%"
        
      />
    </Box>
  );
};

export default LineChart;
