import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditPlan from "./EditPlan";
import ExistPlan from "./ExistPlan";

const ExistingPlans = ({ showComponent, setShowComponent }) => {
  const plans = useSelector((state) => state.adminReducer.plans?.plan_list);

  const [editDetails, setEditDetails] = useState(null);

  const handleEdit = (flag, details) => {
    setShowComponent(flag);
    setEditDetails(details);
  }

  return (
    <div>
      {showComponent === "initial" ? (
        <ExistPlan
          handleEdit={handleEdit}
        />
      ) : (
        <EditPlan editDetails={editDetails} />
      )}
    </div>
  );
};

export default ExistingPlans;
