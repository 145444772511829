import React  from "react";
import styled from "styled-components";

import PlanCard from "./components/PlanCard";
import Overview from "./components/Overview";
import PlanMembers from "./components/PlanMembers";
import OverviewMembers from "./components/OverviewMembers";

const RightSide = styled.div`
  background: #FAF4FD;
`;

function Dashboard() {
  return (
    <div>
      <RightSide>
        <div>
          <PlanCard />
        </div>
        <div className="d-flex" style={{justifyContent: "space-between"}}>
          <Overview />
          {/*<PlanMembers />*/}
        </div>
        <div>
          <OverviewMembers />
        </div>
      </RightSide>
    </div>
  );
}

export default Dashboard;
