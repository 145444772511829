import React  from "react";
import styled from "styled-components";
import PlanTab from "./components/PlanTab";

const RightSide = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const PlanModification = () => {

  return (
    <div className="grid-sec">
      <RightSide open={true}>
        <div className="margin-sec">
          <PlanTab />
        </div>
      </RightSide>
    </div>
  );
};

export default PlanModification;
