import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import BasicLoader from "../../../../components/Loaders/BasicLoader";
import { AUTHENTICATED } from "../../../../constants/authEnums";

const RedirectPage = ({ isMosque, isCustomer, isAdmin }) => {
  const navigate = useNavigate();

  const authState = useSelector((state) => state?.customerReducer?.authState);

  useEffect(() => {
    if (authState === AUTHENTICATED) {
      navigate("/admin/dashboard");
    } else {
      if (isMosque) {
        window.location.href = `/login?isMosque`
      } else if (isCustomer) {
        window.location.href = `/login`
      } else {
        window.location.href = `/admin/login`
      }
    }
  }, [authState])

  return <BasicLoader />;
};

export default RedirectPage;
