import React, { useEffect } from "react";
import styled from "styled-components";
import AdminTab from "./components/AdminTab";
import { useDispatch, useSelector } from "react-redux";
import { fetchExistingAgent } from "../../mosqueAction";

const RightSide = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const AdminsPage = () => {
    const dispatch = useDispatch();

    const activeEntity = useSelector(state => state.mosqueReducer.activeEntity);

    useEffect(() => {
        if (activeEntity?.organisation_id) {
            dispatch(fetchExistingAgent(activeEntity?.organisation_id));
        }
    }, [activeEntity])

  return (
    <div className="grid-sec">
      <RightSide open={true}>
        <div className="margin-sec">
          <AdminTab />
        </div>
      </RightSide>
    </div>
  );
};

export default AdminsPage;
