import React from "react";

export default function BasicLoader(props) {
  return (
    <div
      className={
        props.transparentBg ? "basic-loader-transparent" : "basic-loader"
      }
      data-cy="basic-loader"
    >
      <div className="text-center">
        <img
          width={props.size ? `${props.size}px` : "120px"}
          src={`https://stkiwiwebdev.z23.web.core.windows.net/assets/gifs/Rolling-1s-${
            props.size ? props.size : "120"
          }px.gif`}
          alt=""
        />
      </div>
    </div>
  );
}
