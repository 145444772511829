import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import Header from "../Header";
import LeftSidebar from "../LeftSidebar";
import {
  fetchSaasPlanList,
  dashboardStatsData,
  fetchAdminProfileDetail, fetchApprovalList,
} from "../../adminAction";
import { USER_TYPE_ADMIN } from "../../../../constants/appEnums";

// const RightHeader = styled.div`
//   position: fixed;
//   background: #faf4fd;
//   height: 91px;
//   z-index: 999;
//   width: ${(props) => (props.open ? "80%" : "91%")};
// `;
const RightSide = styled.div`
padding:7.5rem 2rem 2rem 2rem;
  background: #faf4fd;
  height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #9197b3;
    border-radius: 5px;
  }
`;
const RightSideSection = styled.div`
  width: ${(props) => (props.open ? "100%" : "100%")};
`;

const NavbarSidebarAdmin = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const userDetails = useSelector((state) => state.customerReducer?.user);
  const userType = useSelector((state) => state.appReducer?.userType);
  const isAuthenticated = useSelector(
    (state) => state.appReducer?.isAuthenticated
  );

  useEffect(() => {
    if (userType !== USER_TYPE_ADMIN) {
      localStorage.clear();
      window.location.href = `/admin/login`;
    }
  }, [userType]);

  useEffect(() => {
    if (userDetails?._id) {
      dispatch(fetchAdminProfileDetail(userDetails?._id));
    }
    dispatch(fetchSaasPlanList());
    dispatch(dashboardStatsData());
    dispatch(fetchApprovalList());
  }, [dispatch, userDetails]);

  const renderContainer = () => {
    if (
      location?.pathname === "/admin/reset-password" ||
      location?.pathname === "/admin/forget"
    ) {
      return <div className="container-fluid p-0">{children}</div>;
    }
    return (
      <div className="container-fluid p-0">
        <div className="d-flex">
          <LeftSidebar open={!!open} setOpen={setOpen} />
          <RightSideSection open={!!open}>
            {/* <RightHeader open={open}> */}
              <Header />
            {/* </RightHeader> */}
            <RightSide open={true}>{children}</RightSide>
          </RightSideSection>
        </div>
      </div>
    );
  };

  return <>{renderContainer()}</>;
};

NavbarSidebarAdmin.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NavbarSidebarAdmin;
