import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserCard from "./components/UserCard";
import SubscribedUsers from "./components/SubscribedUsers";
import RevenueCard from "./components/RevenueCard";
import {fetchMemberRevenueStats, fetchMemberSubsCount, financialsStatsData} from "../../mosqueAction";
import moment from "moment";

const FinancialPage = () => {
    const dispatch = useDispatch();

    const activeEntity = useSelector(state => state.mosqueReducer.activeEntity);

    useEffect(() => {
        if (activeEntity?._id) {
            // dispatch(financialsStatsData(activeEntity?._id));
            //
            // const startDate = moment().startOf('year').toISOString();
            // const endDate = moment().endOf('year').toISOString();
            // dispatch(fetchMemberSubsCount(startDate, endDate, activeEntity?._id))


            const start_date = moment().startOf('year').subtract(1, 'years').toISOString();
            const end_date = moment().endOf('year').toISOString();
            dispatch(fetchMemberRevenueStats(start_date, end_date, activeEntity?._id));
        }
    }, [activeEntity])

    return (
        <div>
            {/*<UserCard/>*/}
            <RevenueCard/>
            {/*<SubscribedUsers/>*/}
        </div>
    );
}

export default FinancialPage;
