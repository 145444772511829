import React, {useEffect} from 'react'
import BlogTab from './components/BlogTab'
import {useDispatch, useSelector} from "react-redux";
import {fetchAgentBlogList} from "../../mosqueAction";

function BlogListPage() {

    const activeEntity = useSelector(state => state.mosqueReducer.activeEntity);
    const blog_page_num = useSelector(state => state.mosqueReducer.blog_page_num);
    const blog_page_size = useSelector(state => state.mosqueReducer.blog_page_size);

    const dispatch = useDispatch();

    useEffect(() => {
        if (activeEntity?._id) {
            dispatch(fetchAgentBlogList({
                entity_id: activeEntity._id,
                pageNum: blog_page_num,
                pageSize: blog_page_size,
            }))
        }
    }, [dispatch, activeEntity]);

    return (
        <BlogTab/>
    )
}

export default BlogListPage
